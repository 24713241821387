import axios from 'axios'
import _ from 'lodash'
import update from 'immutability-helper'
import { I18n } from 'react-redux-i18n'
import cinemas from '../common/client/cinemas'

import qwInbox from '../common/client/qwInbox'
import { downloadKdm } from '../common/util/helper'

import { displayError } from './app'
import moment from 'moment'

const initialState = {
  pageSizes: [5, 10, 20],
  compositions: [],
  offset: 0,
  hasMore: true,
  fetchInProgress: false,
  cplIds: [],
  enabledExpandAll: false,
  dataReceived: false,
  searchText: '',
  code: '',
  dateRangeShortcutIndex: 0,
  totalCompositionsCount: {
    count: 0,
    isLoading: false
  },
  currentPage: 1,
  dateRange: {
    fromDate: moment()
      .subtract(7, 'days')
      .startOf('day'),
    toDate: moment().endOf('day')
  }
}

initialState.pageSize = initialState.pageSizes[1]

let refetchArray = []

//* EVENTS */
const FETCH_COMPOSITIONS = 'compositions/GET_COMPOSITIONS'
const REMOVE_COMPOSITIONS = 'compositions/REMOVE_COMPOSITIONS'
const TOGGLE_FETCH_IN_PROGESSS = 'compositions/TOGGLE_FETCH_IN_PROGESSS'
const FETCH_COMPOSITIONS_ERROR = 'compositions/FETCH_COMPOSITIONS_ERROR'
const CPLS_OPEN_THEATRES = 'compositions/CPLS_OPEN_THEATRES'
const TOGGLE_EXPAND_COLLAPSE_ALL = 'compositions/TOGGLE_EXPAND_COLLAPSE_ALL'
const UPDATE_THEATRE_INFO = 'compositions/UPDATE_THEATRE_INFO'
const UPDATE_SEARCH_TEXT = 'compositions/UPDATE_SEARCH_TEXT'
const UPDATE_REFRESH_TIME = 'compositions/UPDATE_REFRESH_TIME'
const IS_DATA_FETCHING = 'compositions/IS_DATA_FETCHING'
const SET_CODE = 'compositions/SET_CODE'
const UPDATE_PAGE_SIZE = 'compositions/UPDATE_PAGE_SIZE'
const UPDATE_DATE_RANGE_SHORTCUT_INDEX = 'compositions/UPDATE_DATE_RANGE_SHORTCUT_INDEX'
const UPDATE_COMPOSITIONS_COUNT = 'compositions/UPDATE_COMPOSITIONS_COUNT'
const SET_COUNT_ISLOADING = 'compositions/SET_COUNT_ISLOADING'
const UPDATE_CURRENT_PAGE = 'compositions/UPDATE_CURRENT_PAGE'
const UPDATE_DATE_RANGE = 'compositions/UPDATE_DATE_RANGE'

const { CancelToken } = axios
let source = CancelToken.source()

//* Actions *//

export function updateCurrentPage(payload) {
  return {
    type: UPDATE_CURRENT_PAGE,
    payload
  }
}

export function updateRefreshTime(payload) {
  return {
    type: UPDATE_REFRESH_TIME,
    payload
  }
}

export function isDataFetching(payload) {
  return {
    type: IS_DATA_FETCHING,
    payload
  }
}

export function toggleExpandCollapseAll(payload) {
  return {
    type: TOGGLE_EXPAND_COLLAPSE_ALL,
    payload
  }
}

export function updateCplsToOpenTheatres(payload) {
  return {
    type: CPLS_OPEN_THEATRES,
    payload
  }
}

export function updateTheatreInfo(payload, meta) {
  return {
    type: UPDATE_THEATRE_INFO,
    payload,
    meta
  }
}

export function updateSearchText(payload) {
  return {
    type: UPDATE_SEARCH_TEXT,
    payload
  }
}

export function setCompositions(compositions, nextOffset, refetch) {
  return {
    type: FETCH_COMPOSITIONS,
    payload: {
      compositions,
      nextOffset,
      refetch
    }
  }
}

export function removeCompositions() {
  return {
    type: REMOVE_COMPOSITIONS
  }
}

function toggleFetchInProgess(bool) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    bool
  }
}

export const updatePageSize = pageSize => {
  return {
    type: UPDATE_PAGE_SIZE,
    payload: pageSize
  }
}

export const updateDateRange = dateRange => {
  return {
    type: UPDATE_DATE_RANGE,
    payload: dateRange
  }
}

export const updateDateRangeShortcutIndex = index => {
  return {
    type: UPDATE_DATE_RANGE_SHORTCUT_INDEX,
    payload: index
  }
}

export const setCountIsLoading = payload => {
  return {
    type: SET_COUNT_ISLOADING,
    payload
  }
}

export const updateTotalCompositionsCount = count => {
  return {
    type: UPDATE_COMPOSITIONS_COUNT,
    payload: count
  }
}

export function fetchCompositionsError() {
  return {
    type: FETCH_COMPOSITIONS_ERROR
  }
}
export function setCode(code) {
  return {
    type: SET_CODE,
    payload: code
  }
}

export function loadTheatreDetails(theatreId, cplId) {
  return dispatch => {
    const url = `/compositions?theatreId=${theatreId}&compositionId=${cplId}`
    return qwInbox
      .get(url)
      .then(res => {
        return res.data
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchTheatreInfo')))
      })
  }
}

export function fetchCompositionsCount(payload, searchText, theatreSelectedOptions) {
  let url = `/compositions/count`
  if (searchText) {
    url += `?q=${searchText}`
  }
  if (theatreSelectedOptions?.length) {
    if (!searchText) url += '?'
    for (const theatre of theatreSelectedOptions) {
      url += `&theatreId=${theatre.id}`
    }
  }
  return dispatch => {
    dispatch(setCountIsLoading(true))
    qwInbox
      .post(url, payload)
      .then(res => {
        if (res.data.count) {
          dispatch(updateTotalCompositionsCount(res.data.count))
        } else {
          dispatch(updateTotalCompositionsCount(0))
        }
      })
      .catch(e => {
        console.log('the error of total count is : ', e)
        dispatch(updateTotalCompositionsCount(0))
      })
      .finally(() => {
        dispatch(setCountIsLoading(false)) // Set loading state to false regardless of success or error
      })
  }
}

export function fetchCompositions(fresh = false) {
  if (fresh && source) {
    source.cancel()
    source = CancelToken.source()
  }
  return (dispatch, getState) => {
    const { fetchInProgress, searchText, pageSize, currentPage } = getState().compositions
    const { theatreSelectedOptions } = getState().inboxFilters
    const offset = (currentPage - 1) * pageSize

    const fromDate = getState().compositions.dateRange.fromDate
    const toDate = getState().compositions.dateRange.toDate

    if (fromDate === null || toDate === null) {
      return
    }

    if (!fetchInProgress || fresh) {
      dispatch(toggleFetchInProgess(true))
      if (fresh) {
        dispatch(removeCompositions())
      }

      let url = `/compositions/summary?ps=${pageSize}&offset=${offset}`
      if (searchText) {
        url += `&q=${searchText}`
      }
      if (theatreSelectedOptions.length) {
        for (const theatre of theatreSelectedOptions) {
          url += `&theatreId=${theatre.id}`
        }
      }

      const payload = {
        filters: {
          kdmValidity: getState().inboxFilters.kdmValiditySelectedOptions.flatMap(option =>
            option.value.split('_')
          ),
          cplType: getState().inboxFilters.compositionTypeSelectedOptions.flatMap(option =>
            option.value.split('_')
          ),
          dcpDownloadStatus: getState().inboxFilters.dcpDownloadStatusSelectedOptions.flatMap(
            option => option.value.split('_')
          ),
          dcpIngestStatus: getState().inboxFilters.dcpIngestStatusSelectedOptions.flatMap(option =>
            option.value.split('_')
          ),
          kdmDeliveryStatus: getState().inboxFilters.kdmDeliveryStatusSelectedOptions.flatMap(
            option => option.value
          ),
          kdmIngestStatus: getState().inboxFilters.kdmIngestStatusSelectedOptions.flatMap(option =>
            option.value.split('_')
          )
        },
        fromDate,
        toDate
      }

      dispatch(fetchCompositionsCount(payload, searchText, theatreSelectedOptions))

      qwInbox
        .post(url, payload, {
          cancelToken: source.token
        })
        .then(response => {
          if (response.data.code) {
            dispatch(setCode(response.data.code))
            dispatch(setCompositions([], offset + pageSize, false))
          } else {
            dispatch(setCompositions(response.data, offset + pageSize, false))
          }
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
            dispatch(displayError(I18n.t('errors.fetchCompositions')))
            dispatch(fetchCompositionsError())
          }
        })
        .finally(() => {
          dispatch(toggleFetchInProgess(false))
        })
    }
  }
}

function getDownloadKDMURLs(cplIds, theatreIds) {
  let url = `/kdms/download/urls`
  const params = []
  params.push(cplIds.map(c => `c=${c}`).join('&'))
  params.push(theatreIds.map(t => `t=${t}`).join('&'))
  url = `${url}?${params.join('&')}`

  return qwInbox.get(url)
}

export function downloadKDMs(cplName, cplIds, theatreIds) {
  return dispatch => {
    getDownloadKDMURLs(cplIds, theatreIds)
      .then(response => {
        if (!_.isEmpty(response.data)) {
          downloadKdm(cplName, response.data)
        }
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.downloadKDMs')))
      })
  }
}

export function sendKDMEmail(cplIds, theatreIds, payload, cb) {
  return () => {
    getDownloadKDMURLs(cplIds, theatreIds)
      .then(response => {
        const tempPayload = payload
        if (!_.isEmpty(response.data)) {
          response.data.forEach(({ id, filename, url }) => {
            tempPayload.kdms[id] = {
              filename,
              url
            }
          })
          cinemas
            .post('/api/kdms/email', tempPayload)
            .then(() => cb(true))
            .catch(() => cb(false))
        } else {
          cb(false)
        }
      })
      .catch(() => cb(false))
  }
}

function getCplIdsFromCompositions(compositions) {
  const cplIds = []
  compositions.forEach(cpl => {
    if (cpl.resourceType === 'composition') {
      cplIds.push(cpl.id)
    } else {
      cpl.compositions.forEach(c => cplIds.push(c.id))
    }
  })

  return _.uniq(cplIds)
}

function getCplIdsBasedOnTheatreCount(compositions) {
  const cplIds = []
  compositions.forEach(cpl => {
    if (cpl.resourceType === 'composition') {
      if (cpl.theatres.length === 1) {
        cplIds.push(cpl.id)
      }
    } else {
      cpl.compositions.forEach(c => {
        if (c.theatres.length === 1) {
          cplIds.push(c.id)
        }
      })
    }
  })

  return _.uniq(cplIds)
}

function getUpdatedCompositions(state, action) {
  const { payload, meta } = action
  let updatedComposition = {}
  let movieObj = {}
  let cplIdx = ''
  let movieIdx = ''
  let isMovie = false

  _.cloneDeep(state.compositions).forEach((c, idx) => {
    if (c.resourceType === 'composition' && c.id === meta.cplId) {
      isMovie = false
      updatedComposition = c
      cplIdx = idx
    } else if (c.resourceType === 'movie') {
      c.compositions.forEach((cp, cpIdx) => {
        if (cp.id === meta.cplId) {
          isMovie = true
          movieObj = c
          updatedComposition = cp
          cplIdx = cpIdx
          movieIdx = idx
        }
      })
    }
  })

  const theatreIndex = _.findIndex(updatedComposition.theatres, t => t.id === meta.theatreId)
  let theatre = updatedComposition.theatres[theatreIndex]
  const newTheatre = payload[0].theatres[0]
  const data = {}
  data.city = { $set: newTheatre.city }
  data.province = { $set: newTheatre.province }
  data.country = { $set: newTheatre.country }
  data.timezone = { $set: newTheatre.timezone }

  if (!_.isEmpty(newTheatre.kdmSummary)) {
    data.screens = { $set: newTheatre.screens }
    data.kdms = { $set: newTheatre.kdms }
    data.kdmDeliveryMethods = { $set: newTheatre.kdmDeliveryMethods }
    data.screenCount = {
      $set: newTheatre.screens ? newTheatre.screens.filter(s => s.isKdmIssued).length : 0
    }
    data.kdmSummary = { $set: newTheatre.kdmSummary }
  }

  if (newTheatre.dcp && !_.isEmpty(newTheatre.dcp)) {
    data.dcp = { $set: newTheatre.dcp }
  }
  theatre = update(theatre, data)
  updatedComposition = update(updatedComposition, {
    theatres: {
      $splice: [[theatreIndex, 1, theatre]]
    }
  })
  if (isMovie) {
    movieObj = update(movieObj, { compositions: { $splice: [[cplIdx, 1, updatedComposition]] } })
  }

  return {
    idx: isMovie ? movieIdx : cplIdx,
    updatedComposition: isMovie ? movieObj : updatedComposition
  }
}

//* Reducers *//

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_REFRESH_TIME: {
      return {
        ...state,
        timestamp: action.payload
      }
    }
    case IS_DATA_FETCHING: {
      return {
        ...state,
        fetching: action.payload
      }
    }
    case UPDATE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload
      }
    case UPDATE_DATE_RANGE_SHORTCUT_INDEX:
      return {
        ...state,
        dateRangeShortcutIndex: action.payload
      }
    case SET_COUNT_ISLOADING:
      return {
        ...state,
        totalCompositionsCount: {
          ...state.totalCompositionsCount,
          isLoading: action.payload
        }
      }
    case UPDATE_COMPOSITIONS_COUNT:
      return {
        ...state,
        totalCompositionsCount: {
          ...state.totalCompositionsCount,
          count: action.payload
        }
      }
    case FETCH_COMPOSITIONS: {
      const data = {}
      data.compositions = !action.payload.refetch
        ? { $push: action.payload.compositions }
        : { $set: refetchArray }
      data.fetching = { $set: false }
      data.dataReceived = { $set: true }
      if (state.enabledExpandAll) {
        data.cplIds = { $push: getCplIdsFromCompositions(action.payload.compositions) }
      } else {
        data.cplIds = { $push: getCplIdsBasedOnTheatreCount(action.payload.compositions) }
      }
      return update(state, data)
    }
    case REMOVE_COMPOSITIONS: {
      const data = {}
      data.compositions = { $set: [] }
      data.offset = { $set: 0 }
      data.hasMore = { $set: true }
      data.dataReceived = { $set: false }
      data.cplIds = { $set: [] }

      return update(state, data)
    }

    case TOGGLE_FETCH_IN_PROGESSS: {
      return update(state, { fetchInProgress: { $set: action.bool } })
    }

    case FETCH_COMPOSITIONS_ERROR: {
      return update(state, {
        hasMore: { $set: false },
        fetchInProgress: { $set: false }
      })
    }

    case CPLS_OPEN_THEATRES:
      return update(state, {
        cplIds: { $set: action.payload }
      })

    case TOGGLE_EXPAND_COLLAPSE_ALL: {
      const data = { enabledExpandAll: { $set: action.payload } }
      if (action.payload) {
        data.cplIds = { $set: getCplIdsFromCompositions(state.compositions) }
      } else {
        data.cplIds = { $set: [] }
      }
      return update(state, data)
    }

    case UPDATE_THEATRE_INFO: {
      const { updatedComposition } = getUpdatedCompositions(state, action)
      const { compositions } = state
      const tempArray = []
      compositions.forEach(item => {
        if (item.id === updatedComposition.id) {
          tempArray.push(updatedComposition)
        } else {
          tempArray.push(item)
        }
      })
      return {
        ...state,
        compositions: tempArray
      }
    }
    // return update(state, {compositions: {$splice: [[idx, 1, updatedComposition]]}})

    case UPDATE_SEARCH_TEXT:
      return update(state, { searchText: { $set: action.payload } })
    case SET_CODE:
      return update(state, { code: { $set: action.payload } })
    case UPDATE_CURRENT_PAGE:
      return update(state, { currentPage: { $set: action.payload } })
    case UPDATE_DATE_RANGE:
      return update(state, { dateRange: { $set: action.payload } })

    default:
      return state
  }
}
